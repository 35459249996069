@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

	*,
	::before,
	::after {
		border-style: solid;
		border-width: 0;
	}

	::before,
	::after {
		--tw-content: '';
	}

	#fsInlineEdit {
		font-size: 1.375rem;
	}
}

@layer utilities {
	.fs-tbl-spacing {
		border-spacing: 0 0.75rem;
	}

	.fs-ow-input {
		box-shadow: none !important;
	}
}

.\!fs-min-h-8 {
	min-height: 2rem !important;
}

.\!fs-justify-center {
	justify-content: center !important;
}

.fs-pr-2 {
	padding-right: 0.5rem;
}

.fs-min-w-4 {
	min-width: 1rem;
}

.has-disabled-options [aria-disabled="true"] {
	opacity: 0.5;
}

.has-disabled-options [aria-disabled="true"]:hover {
	cursor: not-allowed;
}